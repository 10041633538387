import { FunctionComponent } from "react";
import { Link } from "react-router-dom";


interface ErrorProps {}

const Error: FunctionComponent<ErrorProps> = () => {
 
  return (
    <>
      {/* {!isMobile && (
        <video
          autoPlay
          muted
          loop
          id="myVideo"
          className="fixed top-0 left-0 min-h-screen min-w-full -z-10 object-cover"
        >
          <source
            // src="https://raw.githubusercontent.com/fuocy/video/master/Studio%20Project%20%E2%80%94%20Kapwing.mp4"
            src="https://static.vecteezy.com/system/resources/previews/008/249/822/mp4/a-white-robot-searching-for-a-404-error-with-a-torch-light-4k-animation-404-page-not-found-error-concept-with-a-robot-4k-footage-system-finding-404-error-with-a-big-torch-light-animated-free-video.mp4"
            type="video/mp4"
          />
        </video>
      )} */}

      <div className="bg-black/60  min-h-screen tw-flex-center " >
      <video
          autoPlay
          muted
          loop
          id="myVideo"
          className="fixed top-0 left-0 min-h-screen min-w-full -z-10 object-cover hidden sm:block"
        >
          <source
            // src="https://raw.githubusercontent.com/fuocy/video/master/Studio%20Project%20%E2%80%94%20Kapwing.mp4"
            src="https://static.vecteezy.com/system/resources/previews/008/249/822/mp4/a-white-robot-searching-for-a-404-error-with-a-torch-light-4k-animation-404-page-not-found-error-concept-with-a-robot-4k-footage-system-finding-404-error-with-a-big-torch-light-animated-free-video.mp4"
            type="video/mp4"
          />
        </video>
        <div>
          <p className="text-[150px] text-white font-semibold leading-none ">
            404
          </p>
          <p className="mt-6 text-white text-2xl text-center ">
            There is nothing here
          </p>
          <div className="flex justify-center ">
            <Link
              to="/home"
              className="px-8 py-2 bg-primary rounded-md text-white text-xl mt-8 inline-block hover:brightness-90 transition duration-300 absolute top-[70%] sm:top-[65%]"
            >
              Go to Homepage
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Error;
