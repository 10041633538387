import { FunctionComponent } from "react";
import {  AiOutlineHome } from "react-icons/ai";
import { BiSearch } from "react-icons/bi";

import { MdOutlineExplore } from "react-icons/md";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link, useLocation } from "react-router-dom";
import {  ToastContainer } from "react-toastify";


const SidebarMini: FunctionComponent = () => {
  const location = useLocation();
  

  

  return (
    <>
      <ToastContainer />

      <div className="shrink-0 max-w-[50px] w-full py-8 flex flex-col items-center justify-between  top-0  ">
        <Link to="/home">
          <LazyLoadImage
            alt="Logo"
            src="/logo.png"
            effect="opacity"
            className="w-10 h-10"
          />
        </Link>
        <div className="flex flex-col gap-9 absolute top-[20%]">
          <Link
            to="/home"
            className={`hover:text-primary transition duration-300 ${
              location.pathname === "/" && "text-primary"
            }`}>
            <AiOutlineHome size={25} />
          </Link>
          <Link
            to="/explore"
            className={`hover:text-primary transition duration-300 ${
              location.pathname === "/explore" && "text-primary"
            }`}>
            <MdOutlineExplore size={25} />
          </Link>
          <Link
            to="/search"
            className={`hover:text-primary transition duration-300 ${
              location.pathname === "/search" && "text-primary"
            }`}>
            <BiSearch size={25} />
          </Link>
        </div>
      </div>
    </>
  );
};

export default SidebarMini;
