import { FC } from "react";
import { HomeFilms } from "../../shared/types";
import Skeleton from "../Common/Skeleton";
import BannerSlider from "../Slider/BannerSlider";
import SectionSlider from "../Slider/SectionSlider";
// import SearchBox from "../Common/SearchBox";


interface MainHomeFilmsProps {
  data: HomeFilms | undefined;
  dataDetail: any;
  isLoadingBanner: boolean;
  isLoadingSection: boolean;
}

const MainHomeFilms: FC<MainHomeFilmsProps> = ({
  data,
  dataDetail,
  isLoadingBanner,
  isLoadingSection,
}) => {
  return (
    <>
      {/* <div className="h-60 w-[70vw] relative flex items-center bg-[url('https://hips.hearstapps.com/hmg-prod.s3.amazonaws.com/images/best-movies-1614634680.jpg')] after:bg-[url('https://hips.hearstapps.com/hmg-prod.s3.amazonaws.com/images/best-movies-1614634680.jpg')] after:object-cover rounded-3xl after:absolute">
      </div> */}
      {/* <SearchBox  /> */}
      <BannerSlider
        films={data?.Trending}
        dataDetail={dataDetail}
        isLoadingBanner={isLoadingBanner}
      />

      <ul className="flex flex-col gap-10 mt-12">
        {isLoadingSection ? (
          <>
            {new Array(2).fill("").map((_, index) => (
              <li key={index}>
                <Skeleton className="mb-3 max-w-[10%] h-8 rounded-md" />
                <SectionSlider films={undefined} key={index} />
              </li>
            ))}
          </>
        ) : (
          Object.entries(data as HomeFilms)
            .filter((section) => section[0] !== "Trending")
            
            .map((section, index) => (
              <li key={index}>
                
                  <div className="flex justify-between items-center">
                    <h2 className="text-2xl  font-medium tracking-wider mb-3 text-white uppercase hover:text-primary ">
                      {section[0]}
                    </h2>
                   
                  </div>
               

                <SectionSlider films={section[1]} />
              </li>
            ))
        )}
      </ul>
    </>
  );
};

export default MainHomeFilms;
