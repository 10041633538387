import React from "react";
import { Link } from "react-router-dom";
import SearchBox from "../components/Common/SearchBox";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Footer from "../components/Footer/Footer"
import { BsFillArrowRightCircleFill } from "react-icons/bs";
const introPage = () => {
  return (
    <>
      <div className="  intro bg-green grid place-content-center mt-[10%]  ">
        <div className="flex justify-center items-center">
          <Link to="/home" className="flex justify-center items-center">
            <LazyLoadImage
              alt="Logo"
              src="/logo.png"
              effect="opacity"
              className="w-20 h-20"
            />
            <h1 className="text-3xl text-white tracking-widest font-semibold uppercase flex justify-center items-center px-[10px]">
              <span>Movies</span>
              <span className="text-primary">Now</span>
            </h1>
          </Link>
        </div>
        <div className="follow"></div>
        <div className=" w-screen  p-[52px]">
          <SearchBox />
        </div>
        <div className="grid place-content-center py-[32px]">
          <div className="addthis_inline_share_toolbox"></div>
        </div>
        <div className="m-[60px]  flex justify-center items-center">
          <button className="px-[15px] py-[8px] border-2 border-solid border-secondary rounded-full  bg-genre text-secondary hover:opacity-95 transition   hover:scale-110 	">
            <Link
              to="/home"
              className="flex justify-center items-center text-lg ">
              Vist FullSite
              <BsFillArrowRightCircleFill className="m-[8px] text-base w-[20px]	h-[20px] " />
            </Link>
          </button>
        </div>
        <div className="text-center font-medium text-base text-white leading-8 mt-[40px] p-[1rem]	">
          MoviesNow is the best movies site to watch free online movies and TV
          series. These are the reason: <br /> - Ads free <br /> - Completely
          free and fast <br /> - Registration free <br /> - Streaming is just 1
          click away <br /> - Large content library: more than 400k movies and
          shows <br /> - Real HD Quality <br /> Don’t waste your time anymore,
          bookmark  <span className="text-primary" >MOVIESNOW</span> website now and start watching movies and TV shows
          online for free with your friends on our website. <br /> Thank you!
        </div>
        <div className="m-[60px]  flex justify-center items-center">
          <button className="px-[15px] py-[8px]  border-2 border-solid border-secondary rounded-full bg-genre text-secondary hover:opacity-95 transition  hover:scale-110	">
            <Link
              to="/home"
              className="flex justify-center items-center text-lg">
              Goto MoviesNow
              <BsFillArrowRightCircleFill className="m-[8px] text-base w-[20px]	h-[20px] " />
            </Link>
          </button>
        </div>
      </div>
      <Footer/>
    </>
  );
};

export default introPage;
