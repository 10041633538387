import React from 'react'
import {Link} from "react-router-dom"
const Submitinfo = () => {
    
  return (
    <>
    
  
   <div className="grid place-content-center mt-[20vh] ">

   <div className="bg-white flex justify-center items-center max-w-[500px] h-[400px] p-[20px] rounded">
    <div className="text-center text-black">
<span className='text-7xl '>&#10004;</span>
    <h1 className='text-3xl '>Contact Form Submitted</h1>
    <h4 className="m-[15px]">We will be in your touch</h4>
    <Link
    to="/home"
    >
        <button className='bg-primary p-[10px] rounded text-white hover:brightness-90'>
             Homepage
        </button>
    </Link>
    </div>
   </div>
   </div>
    
    
    </>
  )
}

export default Submitinfo