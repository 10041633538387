
import { FC } from "react";
import {  AiOutlineHome } from "react-icons/ai";
import { BiSearch } from "react-icons/bi";
import { MdOutlineExplore } from "react-icons/md";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link, useLocation } from "react-router-dom";
import {  ToastContainer } from "react-toastify";
import { useCurrentViewportView } from "../../hooks/useCurrentViewportView";


interface SidebarProps {
  isSidebarActive: boolean;
  setIsSidebarActive: any;
}

const Sidebar: FC<SidebarProps> = ({ isSidebarActive, setIsSidebarActive }) => {
  const location = useLocation();
  
  const { isMobile } = useCurrentViewportView();



 

  return (
    <>
      <ToastContainer />

      

      <div
        className={`shrink-0 md:max-w-[240px] w-[70vw] pl-8 top-0 pt-10  
        md:sticky md:translate-x-0 md:bg-transparent md:shadow-none
    
      -translate-x-full fixed h-screen shadow-md transition duration-300 bg-dark-lighten z-50 ${
        isSidebarActive && "translate-x-0"
      }`}
      >
        {!isMobile && (
          <Link to="/home" className="flex items-center gap-3">
            <LazyLoadImage
              alt="Logo"
              src="/logo.png"
              effect="opacity"
              className="w-10 h-10"
            />
            <h1 className="text-xl text-white tracking-widest font-semibold uppercase">
              <span>Movies</span>
              <span className="text-primary">Now</span>
            </h1>
          </Link>
        )}
        <div
          className={`text-white text-lg font-medium ${
            isSidebarActive ? "-mt-6" : "mt-12"
          }`}
        >
          MENU
        </div>
          {/* <SearchBox /> */}
        <div className="mt-8 ml-4 flex flex-col gap-10">
          <Link
            to="/home"
            className={`flex gap-6 items-center  ${
              location.pathname === "/" &&
              "!text-primary border-r-4 border-primary font-medium"
            } hover:text-white transition duration-300`}
          >
            <AiOutlineHome size={25} />
            <p>Home</p>
          </Link>

          <Link
            to="/explore"
            className={`flex gap-6 items-center  ${
              location.pathname === "/explore" &&
              "!text-primary border-r-4 border-primary font-medium"
            } hover:text-white transition duration-300`}
          >
            <MdOutlineExplore size={25} />
            <p>Explore</p>
          </Link>

          {/* <Link
            to="/shorts"
            className={`flex gap-5 items-center  ${
              location.pathname === "/shorts" &&
              "!text-primary border-r-4 border-primary font-medium"
            } hover:text-white transition duration-300`}
          >
            <TbBrandTiktok size={30} />
            <p>Shorts</p>
          </Link> */}

          <Link
            to="/search"
            className={`flex gap-6 items-center  ${
              location.pathname === "/search" &&
              "!text-primary border-r-4 border-primary font-medium"
            } hover:text-white transition duration-300`}
          >
            <BiSearch size={25} />
            <p>Search</p>
          </Link>
        </div>

        

        
      </div>

      <div
        onClick={() => setIsSidebarActive(false)}
        className={`bg-black/60 z-[5] fixed top-0 left-0 w-full h-full md:opacity-100 transition duration-300 ${
          isSidebarActive ? "opacity-100 visible" : "opacity-0 invisible"
        }`}
      ></div>
    </>
  );
};

export default Sidebar;
