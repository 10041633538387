import { useQuery } from "@tanstack/react-query";
import { FC, useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";
import { BiSearch } from "react-icons/bi";
import SearchBox from "../components/Common/SearchBox";
import Sidebar from "../components/Common/Sidebar";
import Title from "../components/Common/Title";
import Footer from "../components/Footer/Footer";
import MainHomeFilms from "../components/Home/MainHomeFilm";

import {
  getHomeMovies,
  getHomeTVs,
  getMovieBannerInfo,
  getTVBannerInfo,
} from "../services/home";
import { HomeFilms, Item } from "../shared/types";

const Home: FC = () => {
  const [currentTab, setCurrentTab] = useState(
    localStorage.getItem("currentTab") || "tv"
  );
  const [isSidebarActive, setIsSidebarActive] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const {
    data: dataMovie,
    isLoading: isLoadingMovie,
    isError: isErrorMovie,
    error: errorMovie,
  } = useQuery<HomeFilms, Error>(["home-movies"], getHomeMovies);

  const {
    data: dataMovieDetail,
    isLoading: isLoadingMovieDetail,
    isError: isErrorMovieDetail,
    error: errorMovieDetail,
  } = useQuery<any, Error>(
    ["detailMovies", dataMovie?.Trending],
    () => getMovieBannerInfo(dataMovie?.Trending as Item[]),
    { enabled: !!dataMovie?.Trending }
  );

  const {
    data: dataTV,
    isLoading: isLoadingTV,
    isError: isErrorTV,
    error: errorTV,
  } = useQuery<HomeFilms, Error>(["home-tvs"], getHomeTVs);

  const {
    data: dataTVDetail,
    isLoading: isLoadingTVDetail,
    isError: isErrorTVDetail,
    error: errorTVDetail,
  } = useQuery<any, Error>(
    ["detailTvs", dataTV?.Trending],
    () => getTVBannerInfo(dataTV?.Trending as Item[]),
    { enabled: !!dataTV?.Trending }
  );

  if (isErrorMovie) return <p>ERROR: {errorMovie.message}</p>;

  if (isErrorMovieDetail) return <p>ERROR: {errorMovieDetail.message}</p>;

  if (isErrorTV) return <p>ERROR: {errorTV.message}</p>;

  if (isErrorTVDetail) return <p>ERROR: {errorTVDetail.message}</p>;

  return (
    <>
      <Title value="MoviesNow | Watching Website" />

      <div className=" flex md:hidden justify-between	 items-center px-5 my-5">
        <Link to="/" className="flex gap-2 items-center">
          <LazyLoadImage
            src="/logo.png"
            className="h-10 w-10 rounded-full object-cover"
          />
          <p className="text-xl text-white font-medium tracking-wider uppercase">
            Movies<span className="text-primary">Now</span>
          </p>
        </Link>
        <button onClick={() => setIsSidebarActive((prev) => !prev)}>
          <GiHamburgerMenu size={25} />
        </button>
      </div>

      <div className="flex items-start scroll-smooth ">
        <Sidebar
          setIsSidebarActive={setIsSidebarActive}
          isSidebarActive={isSidebarActive}
        />

        <div className="flex-grow md:pt-7 pt-0 pb-7 border-x md:px-[2vw] px-[4vw] border-gray-darken min-h-screen">
          <div className="flex justify-between md:items-end items-center">
            <div className="inline-flex gap-[40px] pb-[14px] border-b border-gray-darken relative">
              <button
                onClick={() => {
                  setCurrentTab("tv");
                  localStorage.setItem("currentTab", "tv");
                }}
                className={`${
                  currentTab === "tv" &&
                  "text-white font-medium after:absolute after:bottom-0 after:left-[-2%] after:bg-primary after:h-[3px] after:w-[50%] after:rounded-full"
                } transition duration-300 hover:text-white`}>
                TV Show
              </button>
              <button
                onClick={() => {
                  setCurrentTab("movie");
                  localStorage.setItem("currentTab", "movie");
                }}
                className={`${
                  currentTab === "movie" &&
                  "text-white font-medium after:absolute after:bottom-0 after:right-[-9%] after:bg-primary after:h-[3px] after:w-[50%] after:rounded-full"
                } transition duration-300 hover:text-white`}>
                Movie
              </button>
            </div>

            <div className="flex   w-[40vw]  justify-end z-10 transition-all duration-500 ease-in-out">
              <div className="invisible md:visible transition-all duration-500 ease-in-out">
                <SearchBox />
              </div>
             
            </div>
              <div className="text-3xl block  md:hidden transition-all duration-500 ease-in-out">
                <BiSearch onClick={() => setSearchOpen(!searchOpen)}  />
                
              </div>
          </div>
          {searchOpen && (
            <div className="m-[15px] transition-all duration-500 ease-in-out">

              <SearchBox/>
            </div>
                
                
              )}
          {currentTab === "movie" && (
            <MainHomeFilms
              data={dataMovie}
              dataDetail={dataMovieDetail}
              isLoadingBanner={isLoadingMovieDetail}
              isLoadingSection={isLoadingMovie}
            />
          )}
          {currentTab === "tv" && (
            <MainHomeFilms
              data={dataTV}
              dataDetail={dataTVDetail}
              isLoadingBanner={isLoadingTVDetail}
              isLoadingSection={isLoadingTV}
            />
          )}
        </div>

        {/* <div className="shrink-0 max-w-[310px] w-full hidden lg:block px-6 top-0 sticky ">
          
          <RecommendGenres currentTab={currentTab} />
          <TrendingNow />
        </div> */}
        
      </div>

      <Footer />
    </>
  );
};

export default Home;
