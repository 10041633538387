import { FC, useState } from "react";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";

import { BsFillPlayFill } from "react-icons/bs";
import { GiHamburgerMenu } from "react-icons/gi";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useCurrentViewportView } from "../../hooks/useCurrentViewportView";

import { DetailMovie, DetailTV, FilmInfo } from "../../shared/types";
import { resizeImage } from "../../shared/utils";
import RightbarFilms from "../Common/RightbarFilms";
import Sidebar from "../Common/Sidebar";
import SidebarMini from "../Common/SidebarMini";
import Skeleton from "../Common/Skeleton";
import Title from "../Common/Title";
import Footer from "../Footer/Footer";
// import RecommendGenres from "../Home/RecommendGenres";
import FilmTabInfo from "./FilmTabInfo";
const FilmDetail: FC<FilmInfo> = ({ similar, videos, detail, ...others }) => {
  const { isMobile } = useCurrentViewportView();
  const [isSidebarActive, setIsSidebarActive] = useState(false);

  return (
    <>
      {detail && (
        <Title
          value={`${
            (detail as DetailMovie).title || (detail as DetailTV).name
          } | Moonlight`}
        />
      )}

      <div className="flex md:hidden justify-between items-center px-5 my-3">
        <Link to="/home" className="flex gap-2 items-center">
          <LazyLoadImage
            src="/logo.png"
            className="h-10 w-10 rounded-full object-cover"
          />
          <p className="text-xl text-white font-medium tracking-wider uppercase">
            Movies<span className="text-primary">Now</span>
          </p>
        </Link>
        <button onClick={() => setIsSidebarActive((prev) => !prev)}>
          <GiHamburgerMenu size={25} />
        </button>
      </div>

      <ToastContainer />

      <div className="flex flex-col md:flex-row">
        {!isMobile && <SidebarMini />}
        {isMobile && (
          <Sidebar
            setIsSidebarActive={setIsSidebarActive}
            isSidebarActive={isSidebarActive}
          />
        )}

        <div className="flex-grow min-h-screen">
          {!detail && (
            <Skeleton className="h-[400px] rounded-bl-2xl "></Skeleton>
          )}
          {detail && (
            <div
              style={{
                backgroundImage: `url(${resizeImage(detail.backdrop_path)})`,
              }}
              className="bg-cover bg-center bg-no-repeat md:h-[400px] h-[300px] w-[100vw] rounded-bl-2xl relative">
              <div className="bg-gradient-to-br from-transparent to-black/70 h-full rounded-bl-2xl">
                <div className="flex flex-col md:flex-row bottom-[-85%] md:bottom-[-20%]  items-start absolute left-1/2 -translate-x-1/2  w-full max-w-[1000px]">
                  <div className="flex gap-5 items-center">
                    <div className="shrink-0 w-[185px] ml-3 md:ml-0">
                      <LazyLoadImage
                        src={resizeImage(detail.poster_path, "w185")}
                        effect="opacity"
                        className="w-full h-full object-cover rounded-md"
                        alt="Poster"
                      />
                    </div>
                    {isMobile && (
                      <Link
                        to="watch"
                        className="flex gap-6 items-center pl-6 pr-12 py-3 rounded-full bg-genre text-secondary hover:brightness-95 transition duration-300 mt-24 cursor-pointer	">
                        <BsFillPlayFill size={25} />
                        <span className="text-lg font-medium">WATCH</span>
                      </Link>
                    )}
                  </div>

                  <div className="flex-grow md:ml-14 ml-6 mt-6 md:mt-0">
                    <div className="md:h-28 flex items-end">
                      <h1 className=" text-white text-[45px] font-bold leading-tight ">
                        {(detail as DetailMovie).title ||
                          (detail as DetailTV).name}
                      </h1>
                    </div>
                    <ul className="flex gap-3 flex-wrap md:mt-7 mt-3">
                      {detail.genres.slice(0, 3).map((genre) => (
                        <li key={genre.id} className="mb-3 ">
                          <Link
                            to={`/explore?genre=${genre.id}`}
                            className="md:px-5 px-3 md:py-2 py-1 rounded-full uppercase font-medium  border-gray-400 text-secondary hover:brightness-95 transition duration-300 bg-genre">
                            {genre.name}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>

                  {!isMobile && (
                    <Link
                      to="watch"
                      className="flex gap-6 items-center pl-6 pr-12 py-3 rounded-full bg-genre text-secondary hover:brightness-95 transition duration-300 mt-24 cursor-pointer	">
                      <BsFillPlayFill size={25} />
                      <span className="text-lg font-medium">WATCH</span>
                    </Link>
                  )}
                </div>
              </div>
            </div>
          )}

          <div className="flex z-20 relative flex-col md:flex-row mt-32 md:mt-0">
            {!isMobile && (
              <div className="shrink-0 md:max-w-[150px] w-full flex items-center md:flex-col justify-center flex-row gap-20 mt-20 md:border-r border-dark-lighten pt-16">
                <div className="flex flex-col gap-6 items-center">
                  <p className="text-white font-medium text-lg">RATING</p>
                  {!isMobile && (
                    <div className="w-16 ">
                      {detail && (
                        <CircularProgressbar
                        
                          value={detail.vote_average}
                          maxValue={10}
                          text={`${detail.vote_average.toFixed(1)}`}
                          styles={buildStyles({
                            textSize: "25px",
                            pathTransition: 'stroke-dashoffset 0.5s ease 0s',
                            pathTransitionDuration: 1,
                            pathColor: `#f4d03f `,
                            textColor: "#fff",
                            trailColor: "transparent",
                            // backgroundColor: "#f4d03f",
                          })}
                        />
                      )}
                      {!detail && (
                        <Skeleton className="w-16 h-16 rounded-full" />
                      )}
                    </div>
                  )}
                  {isMobile && detail && (
                    <p className="text-2xl -mt-3">
                      {detail.vote_average.toFixed(1)}
                    </p>
                  )}
                </div>

                <div className="flex flex-col gap-3 items-center">
                  {detail && (
                    <>
                      <p className="text-white font-medium text-lg">
                        {detail.media_type === "movie"
                          ? "RUNTIME"
                          : "EP LENGTH"}
                      </p>
                      <div className="flex gap-2 items-center">
                        {detail.media_type === "movie" && (
                          <p className="text-2xl">
                            {(detail as DetailMovie).runtime}
                          </p>
                        )}
                        {detail.media_type === "tv" && (
                          <p className="text-2xl">
                            {(detail as DetailTV).episode_run_time[0]}
                          </p>
                        )}
                        <span>min</span>
                      </div>
                    </>
                  )}
                  {!detail && (
                    <>
                      <p className="text-white font-medium text-lg">RUNTIME</p>
                      <Skeleton className="w-14 h-6" />
                    </>
                  )}
                </div>
              </div>
            )}

            <div className="flex-grow min-h-[500px] md:border-r border-dark-lighten md:px-16 px-5 md:py-7 pt-40">
              {/* {!detail && <Skeleton className="w-full h-[500px]" />} */}
              <FilmTabInfo detail={detail} {...others} />
            </div>

            {/* <div className="shrink-0 md:max-w-[300px] w-full px-6 pt-6">
              <p className="text-white font-medium text-lg mb-5">MEDIA</p>
              <ul className="flex flex-col md:gap-[30px] gap-6">
                {videos &&
                  videos.slice(0, 2).map((video) => (
                    <li key={video.id}>
                      <div className="relative h-0 pb-[56.25%]">
                        <YouTube
                          videoId={video.key}
                          opts={{ height: "100%", width: "100%" }}
                        />
                        <iframe
                          frameBorder="0"
                          allowFullScreen
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          title="Video trailer"
                          width="100%"
                          height="100%"
                          src={`https://www.youtube.com/embed/${video.key}?enablejsapi=1&amp;origin=http%3A%2F%2Flocalhost%3A3000&amp;widgetid=1`}
                          id="widget2"
                          className="absolute top-0 left-0 !w-full !h-full"
                        ></iframe>
                      </div>
                      <p className="mt-3 text-lg whitespace-nowrap overflow-hidden text-ellipsis">
                        {video.name}
                      </p>
                    </li>
                  ))}
                {!videos &&
                  [...new Array(2)].map((_, index) => (
                    <li key={index}>
                      <div className="w-full h-0 pb-[56.25%] relative">
                        <Skeleton className="absolute w-full h-full" />
                      </div>
                      <Skeleton className="h-6 w-[70%] mt-3" />
                    </li>
                  ))}
              </ul>
            </div> */}

            {isMobile && (
              <div className="shrink-0 md:max-w-[150px] w-full flex items-center md:flex-col justify-center flex-row gap-20  md:border-r border-dark-lighten md:pt-16 pt-0 md:mt-20 mt-8">
                <div className="flex flex-col gap-6 items-center">
                  <p className="text-white font-medium text-lg">RATING</p>
                 
                  {isMobile && detail && (
                    <p className="text-2xl -mt-3">
                      {detail.vote_average.toFixed(1)}
                    </p>
                  )}
                </div>

                <div className="flex flex-col gap-3 items-center">
                  {detail && (
                    <>
                      <p className="text-white font-medium text-lg">
                        {detail.media_type === "movie"
                          ? "RUNTIME"
                          : "EP LENGTH"}
                      </p>
                      <div className="flex gap-2 items-center">
                        {detail.media_type === "movie" && (
                          <p className="text-2xl">
                            {(detail as DetailMovie).runtime}
                          </p>
                        )}
                        {detail.media_type === "tv" && (
                          <p className="text-2xl">
                            {(detail as DetailTV).episode_run_time[0]}
                          </p>
                        )}
                        <span>min</span>
                      </div>
                    </>
                  )}
                  {!detail && (
                    <>
                      <p className="text-white font-medium text-lg">RUNTIME</p>
                      <Skeleton className="w-14 h-6" />
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
          <RightbarFilms
            name="Similar"
            films={similar?.filter((item) => item.id !== detail?.id)}
            isLoading={!similar}
            className="md:mt-24 mt-12"
          />
        </div>

        <div className="shrink-0 md:max-w-[310px] w-full relative px-6 ">
          {/* {!isMobile && <SearchBox />} */}
          {/* <RecommendGenres /> */}
         
        </div>
      </div>

      <Footer />
    </>
  );
};

export default FilmDetail;
