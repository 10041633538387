import { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Contact from "./pages/Contact";

import Error from "./pages/Error";
import Explore from "./pages/Explore";
import Home from "./pages/Home";
import IntroPage from "./pages/introPage";
import MovieInfo from "./pages/Movie/MovieInfo";
import MovieWatch from "./pages/Movie/MovieWatch";

import PrivacyPolicy from "./pages/PrivacyPolicy";

import Search from "./pages/Search";
import Submitinfo from "./pages/Submitinfo";
import TermsCondition from "./pages/TermsCondition";
import TVInfo from "./pages/TV/TVInfo";
import TVWatch from "./pages/TV/TVWatch";

function App() {
  const location = useLocation();

  // const currentUser = useAppSelector((state) => state.auth.user);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname, location.search]);

  return (
    <Routes>
      <Route index element={<IntroPage />} />
      <Route path="home" element={<Home />} />
      <Route path="movie/:id" element={<MovieInfo />} />

      <Route path="tv/:id" element={<TVInfo />} />
      <Route path="movie/:id/watch" element={<MovieWatch />} />
      <Route path="tv/:id/watch" element={<TVWatch />} />
      <Route path="explore" element={<Explore />} />
      <Route path="search" element={<Search />} />
      <Route path="terms-condition" element={<TermsCondition />} />
      <Route path="privacy-policy" element={<PrivacyPolicy />} />
      <Route path="contact" element={<Contact />} />
      <Route path="submit" element={<Submitinfo />} />

      <Route path="*" element={<Error />} />
    </Routes>
  );
}

export default App;
