import { FC } from "react";

import { Item } from "../../shared/types";
import FilmItem from "../Common/FilmItem";
import Skeleton from "../Common/Skeleton";

interface SectionSliderProps {
  films: Item[] | undefined;
}

const SectionSlider: FC<SectionSliderProps> = ({ films }) => {
  return (
    <div className="relative grid grid-cols-sm lg:grid-cols-lg gap-x-8 gap-y-10 pt-2 ">
      

        {films?.map((film) => (
          <>
          <div className="movie-card" key={film.id}>


            <FilmItem item={film} key={film.id} />
          </div>
          </>
         
        )) || (
          <>
            {new Array(Math.ceil(window.innerWidth / 200))
              .fill("")
              .map((_, index) => (
               
                  <Skeleton className="!w-[175px] !h-[280px] shadow-sm" key={index} />
                
              ))}
          </>
        )}
       
       
      
  
    </div>
  );
};

export default SectionSlider;

// OLD ITEM

  /* <Link to={film.media_type === "movie" ? `movie/${film.id}` : `tv/${film.id}`}>
  <div className="shadow-sm bg-dark-darken pb-2 rounded-md overflow-hidden hover:scale-105 hover:brightness-110 transition duration-300 relative group">
    <LazyLoadImage
      alt="Poster film"
      src={resizeImage(film.poster_path, "w185")}
      className="object-cover"
      effect="blur"
    />
    <p className="whitespace-nowrap overflow-hidden text-ellipsis text-base text-gray-300 mt-1 text-center px-2 group-hover:text-white transition duration-300">
      {film.title || film.name}
    </p>
    <div className="bg-primary px-2 py-1 rounded-full absolute top-[5%] left-[8%] z-20 flex items-center gap-1 text-white text-xs">
      {film.vote_average.toFixed(1)}
      <AiFillStar size={15} />
    </div>
  </div>
</Link>; */

