import { FunctionComponent } from "react";
// import { AiFillGithub } from "react-icons/ai";
// import { BsFacebook } from "react-icons/bs";
import { LazyLoadImage } from "react-lazy-load-image-component";
import {Link} from "react-router-dom"
interface FooterProps {}

const Footer: FunctionComponent<FooterProps> = () => {

  const current = new Date();
  const date = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;
  const locale = 'en';
  const today = new Date();
  
  const time = today.toLocaleTimeString(locale, { hour: 'numeric', hour12: true, minute: 'numeric' });
  return (

    <>

    <div className="bg-dark-lighten text-white  py-3 px-4 shadow-md mt-3">
<div className="block justify-evenly items-center  md:flex">
  <div className="1">
  <Link to="/home" className="hidden lg:flex justify-center items-center">
            <LazyLoadImage
              alt="Logo"
              src="/logo.png"
              effect="opacity"
              className="w-10 h-10 hidden lg:block"
            />
            <h1 className="text-xl text-white tracking-widest font-semibold uppercase flex justify-center items-center px-[10px]">
              <span>Movies</span>
              <span className="text-primary">Now</span>
            </h1>
          </Link>
  </div>
  <div className="md:w-[50%] ">
    <h5 className="opacity-80 text-sm" >MoviesNow is a Free Movies streaming site with zero ads. We let you watch movies online without having to register or paying, with over 10000 movies and TV-Series. You can also Download full movies from MoviesNow and watch it later if you want.</h5>
    <div className=" justify-start items-center flex text-base my-[18px]">

    <Link
    to="/terms-condition"
    className="mx-[5px] hover:text-primary"
    >
      <h3>

    Terms & Condition
      </h3>
    </Link>
    <Link
    to="/privacy-policy"
    className="mx-[5px] hover:text-primary  "
    >
      <h3>

    Privacy & Policy
      </h3>
    </Link>
    <Link
    to="/contact"
    className="mx-[5px] hover:text-primary  "
    >
      <h3>

    Contact
      </h3>
    </Link>
    </div>
  </div>
  <div className="border md:w-[200px] w-[80vw] text-xs p-[12px] relative">
  <span className="animate-ping  absolute top-[-6px] right-[-7px] inline-flex h-[13px] w-[13px] rounded-full bg-red-400 "></span>
  <span className="  absolute top-[-5px] right-[-5px] inline-flex h-[10px] w-[10px] rounded-full bg-red-500 "></span>
    <p>MoviesNow does not store any files on our server, we only linked to the media which is hosted on "<span className=" text-red-400">3rd party services</span>".</p>
  </div>
</div>

      <p className="block md:flex gap-2 my-[10px]">
        <span>&copy;Copyright by Movies <span className="text-primary">Now</span> </span>
        <br />
        <span className="block"> &copy; {date} and {time}  </span>
      </p>
      
    </div>
    </>
  );
};

export default Footer;
