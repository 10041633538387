import { FunctionComponent } from "react";
import FilterBy from "./FilterBy";
import SortBy from "./SortBy";
import SearchBox from "../Common/SearchBox"
interface ExploreFilterProps {
  currentTab: string;
}

const ExploreFilter: FunctionComponent<ExploreFilterProps> = ({
  currentTab,
}) => {
  return (
    <>
    <div className="my-[12px]">

    <SearchBox/>
    </div>

      <SortBy />
      <FilterBy currentTab={currentTab} />
    </>
  );
};

export default ExploreFilter;
